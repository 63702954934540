<template>
  <div>
    <b-modal
      :id="paymentRuleId"
      no-close-on-backdrop
      ok-title="確認"
      centered
      ok-only
      @ok="handleOk"
    >
      <template #modal-title>
        <h4 class="m-0 model-header">
          {{ selectedIndex !== null ? '編輯' : '新增' }}參數
        </h4>
      </template>

      <div>
        <b-row>
          <!-- 參數名稱 -->
          <b-col
            cols="12"
            lg="7"
          >
            <b-form-group
              label="參數名稱"
              label-for="config-label"
            >
              <b-form-input
                id="config-label"
                v-model="ruleData.label"
                name="config-label"
                trim
              />
            </b-form-group>
          </b-col>

          <!-- 參數 -->
          <b-col
            cols="12"
            lg="5"
          >
            <b-form-group
              label="KEY"
              label-for="config-key"
            >
              <b-form-input
                id="config-key"
                v-model="ruleData.key"
                name="config-key"
                trim
                placeholder="key"
              />
            </b-form-group>
          </b-col>

          <!-- 預設 -->
          <b-col
            v-if="selectedIndex === null"
            cols="12"
          >
            <b-form-group
              label="預設值"
              label-for="config-default"
            >
              <b-form-input
                id="config-default"
                v-model="ruleData.value"
                name="config-default"
                placeholder="請填入預設值"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
// API
import { ref } from '@vue/composition-api'

// UI
import {
  BRow, BCol, VBTooltip, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { useAlert } from '@/libs/mixins/index'
import { usePaymentSetting } from '../../usePayment'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    paymentRuleId: {
      type: String,
      default: 'payment-rule-modal',
    },
    configArray: {
      type: Array,
      default: () => [],
    },
    selectedIndex: {
      type: Number || null,
      default: null,
    },
  },
  data() {
    return {
      missingFields: [],
    }
  },
  methods: {
    // 按下彈窗確認
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()

      const requiredFields = {
        label: '參數名稱',
        key: 'KEY',
      }

      this.missingFields = []

      for (let i = 0; i < Object.keys(requiredFields).length; i += 1) {
        if (this.ruleData[Object.keys(requiredFields)[i]] === null || this.ruleData[Object.keys(requiredFields)[i]] === '') {
          this.missingFields.push({
            label: Object.values(requiredFields)[i],
            value: Object.keys(requiredFields)[i],
          })
        }
      }

      if (this.missingFields.length > 0) {
        this.useAlertToast(false, `${this.missingFields[0].label}不得為空`)
        return
      }

      this.$nextTick(() => {
        if (this.selectedIndex === null) {
          const sameKey = this.configArray.some(item => item.key === this.ruleData.key)
          if (sameKey) {
            this.useAlertToast(false, 'KEY值不可重複')
            return
          }
        } else {
          const sameKey = this.configArray.some((item, index) => index !== this.selectedIndex && item.key === this.ruleData.key)
          if (sameKey) {
            this.useAlertToast(false, 'KEY值不可重複')
            return
          }
        }
        this.$emit('edit-rule', this.ruleData)
        this.$bvModal.hide(this.paymentRuleId)
      })
    },

    // (取得)編輯資料
    getData(rule) {
      const resolveData = {
        ...this.syncObject(this.blankRuleData, rule),
      }

      this.ruleData = resolveData
      this.$bvModal.show(this.paymentRuleId)
    },
  },
  setup() {
    const blankRuleData = {
      key: null,
      label: null,
      value: null,
    }

    const ruleData = ref(JSON.parse(JSON.stringify(blankRuleData)))

    const resetRuleData = () => {
      ruleData.value = JSON.parse(JSON.stringify(blankRuleData))
    }

    const {
      syncObject,
    } = usePaymentSetting()

    const {
      useAlertToast,
    } = useAlert()

    return {
      ruleData,
      blankRuleData,
      resetRuleData,

      syncObject,
      useAlertToast,
    }
  },
}
</script>

<style lang="scss" scoped>
.model-header {
  padding: 5px 0;
}
</style>
